<template>
  <div class="d-flex align-center justify-space-between px-0 mt-7">
    <v-btn
      class="grey-button mr-2"
      style="flex:1"
      height="48px"
      @click="$router.back()"
    >
      Cancel
    </v-btn>
    <v-btn
      :class="['violet-button', 'ml-2', { 'disabled':  disabled }]"
      :disabled="disabled"
      style="flex:1"
      height="48px"
      @click="$emit('ok')"
    >
      {{ ok }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'FooterButtons',
  props: {
    ok: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-btn.v-btn--disabled {
  background: grey !important;
  color: #ccc !important;
  border-color: grey;
}
</style>
