<template>
  <v-app-bar
    app
    color="#ffffff"
    dark
    height="56px"
    class="pl-4 pr-12 pr-md-3"
    style="box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15) !important"
>
    <span v-if="!hideChanges" class="fz-16 font-weight-semi-bold" style="color: #212B36">{{ changed ? 'Save changes' : 'Unsaved changes' }}</span>
    <div class="mr-auto regular-text--text font-weight-semi-bold"></div>
    <v-btn
      v-if="hasExport"
      class="grey-button mr-5 export"
      @click="$emit('export')"
    >
      Export {{ exportName }}
    </v-btn>
    <v-btn
      class="grey-button mr-2"
      @click="$router.back()"
    >
      Cancel
    </v-btn>
    <v-btn
      v-if="ok"
      :class="['violet-button', 'mr-4', { 'disabled':  disabled }]"
      :disabled="disabled"
      @click="$emit('ok')"
    >
      {{ ok }}
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderButtons',
  data: () => ({
    app: true
  }),
  props: {
    ok: {
      type: String,
      default: ''
    },
    changed: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasExport: {
      type: Boolean,
      default: false
    },
    exportName: {
      type: String,
      default: ''
    },
    hideChanges: {
      type: Boolean,
      default: false
    }
  },
  beforeDestroy () {
    this.app = false
  }
}
</script>

<style lang="scss" scoped>
::v-deep .theme--dark.v-btn.v-btn--disabled {
  background: grey !important;
  color: #ccc !important;
  border-color: grey;
}

.v-btn {
  min-width: 67px !important;
  padding: 0 !important;
  &.export {
    min-width: 97px !important;
  }
}
</style>
